'use client'

import { LicenseInfo } from '@mui/x-license'

// https://mui.com/x/introduction/licensing/#next-js-app-router
LicenseInfo.setLicenseKey(
  'c4057de701c35e53788a1a8ee788423eTz05NzIxNixFPTE3NTY2NDQwNjIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg=='
)

export default function MuiXLicense() {
  return null
}
